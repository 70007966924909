<script lang="ts">
  // import landingGpuLine from "$lib/assets/marketing/landing-gpu-line.svg";
  import LandingGpuLine from "$lib/assets/marketing/LandingGpuLine.svelte";
</script>

<div
  class="flex flex-col border border-white/20 rounded-xl bg-black/10 tabular-nums w-full overflow-hidden items-start"
>
  <div
    class="bg-white/10 text-sm md:text-base font-normal rounded-t-xl py-1.5 px-4 w-full"
  >
    GPU Containers
  </div>
  <div class="pt-6 pb-2 flex overflow-auto loop">
    <div class="w-[713px] min-w-0"><LandingGpuLine /></div>
    <div class="w-[713px] min-w-0"><LandingGpuLine /></div>
  </div>
</div>

<style lang="postcss">
  .loop {
    animation: shift 25s linear infinite;
  }

  @keyframes shift {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
</style>
